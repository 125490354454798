<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="12">
              <el-page-header
                class="back"
                title="返回"
                @back="goBack"
                content="横幅详情"
              ></el-page-header>
            </el-col>
            <el-col style="text-align: right" :span="12">
              <el-button
                type="primary"
                icon="el-icon-check"
                @click="saveModelOnclick"
                >保存</el-button
              >
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-form
          class="type-form"
          :model="edit_model"
          :rules="rules"
          ref="category_model"
          label-width="140px"
        >
          <CopyPaste
            :button_status="
              this.edit_model_id !== undefined && this.edit_model_id.length > 0
            "
            @copyInfo="copyInfo(edit_model)"
            @pasteInfo="pasteInfo"
            ref="copyPaste"
          ></CopyPaste>
          <el-form-item label="位置:" prop="class_id">
            <el-select
              v-model="edit_model.class_id"
              placeholder="请选择"
              loading-text="加载中..."
            >
              <el-option
                v-for="item in class_list"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态:">
            <el-select
              v-model="edit_model.status"
              placeholder="请选择"
              loading-text="加载中..."
            >
              <el-option
                v-for="item in $common.StatusSelectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="标题:" prop="title">
            <el-input v-model="edit_model.title" clearable></el-input>
          </el-form-item>
          <el-form-item label="子标题:">
            <el-input v-model="edit_model.subtitle" clearable></el-input>
          </el-form-item>
          <el-form-item label="内容:">
            <el-input v-model="edit_model.msg" clearable></el-input>
          </el-form-item>
          <el-form-item label="跳转类型:" prop="last_type">
            <el-select
              v-model="edit_model.last_type"
              placeholder="请选择"
              loading-text="加载中..."
            >
              <el-option
                v-for="item in last_type_list"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="跳转内容:" prop="last_page">
            <el-input v-model="edit_model.last_page" clearable></el-input>
          </el-form-item>
          <el-form-item label="轮播图权重:">
            <el-input-number
              v-model="edit_model.number"
              :min="0"
              label="权重"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="轮播图类型:" prop="banner_type">
            <el-select
              v-model="edit_model.banner_type"
              placeholder="请选择"
              loading-text="加载中..."
            >
              <el-option
                v-for="item in banner_type_list"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="轮播图停留时间(秒):" prop="banner_time">
            <el-input-number
              v-model="edit_model.banner_time"
              :min="0"
              label="权重"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="PC背景图:">
            <el-input v-model="edit_model.pc_bg_source" clearable></el-input>
            <div class="gray">(建议尺寸: 宽1920px; 高:1080px;)</div>
            <el-form
              label-width="60px"
              :inline="true"
              :model="pc_bg_json"
              style="margin-bottom: 10px"
            >
              <el-form-item label="宽(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="pc_bg_json.width"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(0)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="高(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="pc_bg_json.height"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(0)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="上(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="pc_bg_json.top"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(0)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="下(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="pc_bg_json.bottom"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(0)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="左(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="pc_bg_json.left"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(0)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="右(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="pc_bg_json.right"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(0)"
                ></el-input-number>
              </el-form-item>
            </el-form>
            <el-input
              v-model="edit_model.pc_bg_style"
              :disabled="true"
            ></el-input>
            <el-row>
              <el-col :span="12">
                <el-image
                  style="width: 100%; height: 100%; margin-top: 10px"
                  fit="contain"
                  :src="
                    edit_model.pc_bg_source +
                    $common.K_QINIU_IMAGE_SMALL_END_200
                  "
                  :preview-src-list="[edit_model.pc_bg_source]"
                  lazy
                ></el-image>
              </el-col>
              <el-col :span="12">
                <dl style="margin-left: 20px">
                  <dt class="edit-row-dt">
                    <el-button
                      size="mini"
                      type="warning"
                      @click="getImageByWeb('pc_bg_source')"
                      >资源选择</el-button
                    >
                  </dt>
                </dl>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="PC标题图:">
            <el-input v-model="edit_model.pc_title_source" clearable></el-input>
            <el-form
              label-width="60px"
              :inline="true"
              :model="pc_title_json"
              style="margin-bottom: 10px"
            >
              <el-form-item label="宽(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="pc_title_json.width"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(1)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="高(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="pc_title_json.height"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(1)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="上(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="pc_title_json.top"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(1)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="下(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="pc_title_json.bottom"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(1)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="左(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="pc_title_json.left"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(1)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="右(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="pc_title_json.right"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(1)"
                ></el-input-number>
              </el-form-item>
            </el-form>
            <el-input
              v-model="edit_model.pc_title_style"
              :disabled="true"
            ></el-input>
            <el-row>
              <el-col :span="12">
                <el-image
                  style="width: 100%; height: 100%; margin-top: 10px"
                  fit="contain"
                  :src="
                    edit_model.pc_title_source +
                    $common.K_QINIU_IMAGE_SMALL_END_200
                  "
                  :preview-src-list="[edit_model.pc_title_source]"
                  lazy
                ></el-image>
              </el-col>
              <el-col :span="12">
                <dl style="margin-left: 20px">
                  <dt class="edit-row-dt">
                    <el-button
                      size="mini"
                      type="warning"
                      @click="getImageByWeb('pc_title_source')"
                      >资源选择</el-button
                    >
                  </dt>
                </dl>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="PC参数图:">
            <el-input v-model="edit_model.pc_info_source" clearable></el-input>
            <el-form
              label-width="60px"
              :inline="true"
              :model="pc_info_json"
              style="margin-bottom: 10px"
            >
              <el-form-item label="宽(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="pc_info_json.width"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(2)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="高(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="pc_info_json.height"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(2)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="上(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="pc_info_json.top"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(2)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="下(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="pc_info_json.bottom"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(2)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="左(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="pc_info_json.left"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(2)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="右(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="pc_info_json.right"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(2)"
                ></el-input-number>
              </el-form-item>
            </el-form>
            <el-input
              v-model="edit_model.pc_info_style"
              :disabled="true"
            ></el-input>
            <el-row>
              <el-col :span="12">
                <el-image
                  style="width: 100%; height: 100%; margin-top: 10px"
                  fit="contain"
                  :src="
                    edit_model.pc_info_source +
                    $common.K_QINIU_IMAGE_SMALL_END_200
                  "
                  :preview-src-list="[edit_model.pc_info_source]"
                  lazy
                ></el-image>
              </el-col>
              <el-col :span="12">
                <dl style="margin-left: 20px">
                  <dt class="edit-row-dt">
                    <el-button
                      size="mini"
                      type="warning"
                      @click="getImageByWeb('pc_info_source')"
                      >资源选择</el-button
                    >
                  </dt>
                </dl>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="WAP背景图:">
            <el-input v-model="edit_model.wap_bg_source" clearable></el-input>
            <div class="gray">(建议尺寸: 宽750px; 高:1340px;)</div>
            <el-form
              label-width="60px"
              :inline="true"
              :model="wap_bg_json"
              style="margin-bottom: 10px"
            >
              <el-form-item label="宽(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="wap_bg_json.width"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(3)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="高(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="wap_bg_json.height"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(3)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="上(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="wap_bg_json.top"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(3)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="下(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="wap_bg_json.bottom"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(3)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="左(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="wap_bg_json.left"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(3)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="右(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="wap_bg_json.right"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(3)"
                ></el-input-number>
              </el-form-item>
            </el-form>
            <el-input
              v-model="edit_model.wap_bg_style"
              :disabled="true"
            ></el-input>
            <el-row>
              <el-col :span="12">
                <el-image
                  style="width: 100%; height: 100%; margin-top: 10px"
                  fit="contain"
                  :src="
                    edit_model.wap_bg_source +
                    $common.K_QINIU_IMAGE_SMALL_END_200
                  "
                  :preview-src-list="[edit_model.wap_bg_source]"
                  lazy
                ></el-image>
              </el-col>
              <el-col :span="12">
                <dl style="margin-left: 20px">
                  <dt class="edit-row-dt">
                    <el-button
                      size="mini"
                      type="warning"
                      @click="getImageByWeb('wap_bg_source')"
                      >资源选择</el-button
                    >
                  </dt>
                </dl>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="WAP标题图:">
            <el-input
              v-model="edit_model.wap_title_source"
              clearable
            ></el-input>
            <el-form
              label-width="60px"
              :inline="true"
              :model="wap_title_json"
              style="margin-bottom: 10px"
            >
              <el-form-item label="宽(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="wap_title_json.width"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(4)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="高(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="wap_title_json.height"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(4)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="上(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="wap_title_json.top"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(4)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="下(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="wap_title_json.bottom"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(4)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="左(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="wap_title_json.left"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(4)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="右(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="wap_title_json.right"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(4)"
                ></el-input-number>
              </el-form-item>
            </el-form>
            <el-input
              v-model="edit_model.wap_title_style"
              :disabled="true"
            ></el-input>
            <el-row>
              <el-col :span="12">
                <el-image
                  style="width: 100%; height: 100%; margin-top: 10px"
                  fit="contain"
                  :src="
                    edit_model.wap_title_source +
                    $common.K_QINIU_IMAGE_SMALL_END_200
                  "
                  :preview-src-list="[edit_model.wap_title_source]"
                  lazy
                ></el-image>
              </el-col>
              <el-col :span="12">
                <dl style="margin-left: 20px">
                  <dt class="edit-row-dt">
                    <el-button
                      size="mini"
                      type="warning"
                      @click="getImageByWeb('wap_title_source')"
                      >资源选择</el-button
                    >
                  </dt>
                </dl>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="WAP参数图:">
            <el-input v-model="edit_model.wap_info_source" clearable></el-input>
            <el-form
              label-width="60px"
              :inline="true"
              :model="wap_info_json"
              style="margin-bottom: 10px"
            >
              <el-form-item label="宽(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="wap_info_json.width"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(5)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="高(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="wap_info_json.height"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(5)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="上(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="wap_info_json.top"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(5)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="下(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="wap_info_json.bottom"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(5)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="左(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="wap_info_json.left"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(5)"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="右(px):" style="margin-top: 10px">
                <el-input-number
                  v-model="wap_info_json.right"
                  style="width: 150px"
                  controls-position="right"
                  @change="styleInputOnclick(5)"
                ></el-input-number>
              </el-form-item>
            </el-form>
            <el-input
              v-model="edit_model.wap_info_style"
              :disabled="true"
            ></el-input>
            <el-row>
              <el-col :span="12">
                <el-image
                  style="width: 100%; height: 100%; margin-top: 10px"
                  fit="contain"
                  :src="
                    edit_model.wap_info_source +
                    $common.K_QINIU_IMAGE_SMALL_END_200
                  "
                  :preview-src-list="[edit_model.wap_info_source]"
                  lazy
                ></el-image>
              </el-col>
              <el-col :span="12">
                <dl style="margin-left: 20px">
                  <dt class="edit-row-dt">
                    <el-button
                      size="mini"
                      type="warning"
                      @click="getImageByWeb('wap_info_source')"
                      >资源选择</el-button
                    >
                  </dt>
                </dl>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="PC视频:">
            <el-input v-model="edit_model.pc_video" clearable></el-input>
          </el-form-item>
          <el-form-item label="WAP视频:">
            <el-input v-model="edit_model.wap_video" clearable></el-input>
          </el-form-item>
          <el-form-item label="开始展示时间:" prop="start_at">
            <el-date-picker
              v-model="edit_model.start_at"
              type="datetime"
              placeholder="选择日期时间"
              default-time="12:00:00"
              align="center"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="结束展示时间:" prop="end_at">
            <el-date-picker
              v-model="edit_model.end_at"
              type="datetime"
              placeholder="选择日期时间"
              default-time="12:00:00"
              align="center"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="banner预览：">
            <a
              :href="K_PREVIEW_DOMAIN + '?copywriter=' + $common.Copywriter"
              target="_blank"
            >
              <el-button
                style="width: 100px; height: 50px"
                type="primary"
                :disabled="!edit_model_id"
                >预览</el-button
              >
            </a>
          </el-form-item>
        </el-form>
      </el-main>
    </el-container>
    <FileChooseDrawerModule ref="bannerFileChooseDrawerModule" />
  </div>
</template>

<script>
import FileChooseDrawerModule from "../../file/module/FileChooseDrawerModule";
import CopyPaste from "../../../components/CopyPaste.vue";
import { K_PREVIEW_DOMAIN } from "../../../../public/static/js/config";

export default {
  name: "BannerDetailView",
  components: {
    FileChooseDrawerModule,
    CopyPaste,
  },
  data() {
    return {
      K_PREVIEW_DOMAIN,
      loading: false,
      rules: {
        class_id: [
          {
            required: true,
            message: "请选择分类",
            trigger: "change",
          },
        ],
        title: [
          { required: true, message: "请输入标题", trigger: "blur" },
          { min: 1, message: "长度大于 1 个字符", trigger: "blur" },
        ],
        last_type: [
          {
            required: true,
            message: "请选择跳转类型",
            trigger: "change",
          },
        ],
        last_page: [
          { required: true, message: "请输入跳转内容", trigger: "blur" },
          { min: 1, message: "长度大于 1 个字符", trigger: "blur" },
        ],
        banner_type: [
          { required: true, message: "请选择轮播图类型", trigger: "change" },
        ],
        banner_time: [
          { required: true, message: "请输入轮播图停留时间", trigger: "blur" },
        ],
        start_at: [
          { required: true, message: "请输入开始展示时间", trigger: "blur" },
        ],
        end_at: [
          { required: true, message: "请输入结束展示时间", trigger: "blur" },
        ],
      },
      last_type_list: [
        {
          id: 1,
          name: "内部链接",
        },
        {
          id: 2,
          name: "外部链接",
        },
      ],
      banner_type_list: [
        {
          id: 1,
          name: "图片",
        },
        {
          id: 2,
          name: "图文",
        },
        {
          id: 3,
          name: "视频",
        },
      ],
      edit_model_id: "",
      edit_model: {
        id: "",
        class_id: "",
        title: "",
        subtitle: "",
        msg: "",
        last_type: 1,
        last_page: "",
        number: 0,
        banner_type: 1,
        banner_time: 5,
        pc_bg_source: "",
        pc_bg_style: "",
        pc_title_source: "",
        pc_title_style: "",
        pc_info_source: "",
        pc_info_style: "",
        wap_bg_source: "",
        wap_bg_style: "",
        wap_title_source: "",
        wap_title_style: "",
        wap_info_source: "",
        wap_info_style: "",
        pc_video: "",
        wap_video: "",
        start_at: "",
        end_at: "",
        status: 2,
      },
      pc_bg_json: {
        width: 0,
        height: 0,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      pc_title_json: {
        width: 0,
        height: 0,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      pc_info_json: {
        width: 0,
        height: 0,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      wap_bg_json: {
        width: 0,
        height: 0,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      wap_title_json: {
        width: 0,
        height: 0,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      wap_info_json: {
        width: 0,
        height: 0,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      class_list: [],
    };
  },
  created() {
    // 获取配置规则
    this.edit_model_id = this.$route.query.id;
    this.getClassList();
    if (this.edit_model_id !== undefined && this.edit_model_id.length > 0) {
      // 编辑
      this.getEditModel();
    }
  },
  methods: {
    getClassList() {
      let _me = this;
      let key = "bannerClassList";
      let val = _me.$common.getGlobalStorage(key);
      if (val) {
        _me.class_list = val;
        return;
      }

      let params = {
        status: 0,
        page_size: 20,
        page_number: 1,
        sort_fields: "",
      };
      _me.loading = true;
      _me.api
        .get_banner_class_list(params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.class_list = res.data.array === undefined ? [] : res.data.array;

            _me.$common.setGlobalStorage(key, _me.class_list);
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    getEditModel() {
      let _me = this;
      _me.loading = true;
      let params = {
        id: _me.edit_model_id,
      };
      _me.api
        .get_banner_id(params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.edit_model = res.data;
            if (_me.edit_model.pc_bg_json !== "") {
              _me.pc_bg_json = JSON.parse(_me.edit_model.pc_bg_json);
            }
            if (_me.edit_model.pc_title_json !== "") {
              _me.pc_title_json = JSON.parse(_me.edit_model.pc_title_json);
            }
            if (_me.edit_model.pc_info_json !== "") {
              _me.pc_info_json = JSON.parse(_me.edit_model.pc_info_json);
            }
            if (_me.edit_model.wap_bg_json !== "") {
              _me.wap_bg_json = JSON.parse(_me.edit_model.wap_bg_json);
            }
            if (_me.edit_model.wap_title_json !== "") {
              _me.wap_title_json = JSON.parse(_me.edit_model.wap_title_json);
            }
            if (_me.edit_model.wap_info_json !== "") {
              _me.wap_info_json = JSON.parse(_me.edit_model.wap_info_json);
            }
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    getImageByWeb(imageType) {
      let _me = this;
      _me.$refs.bannerFileChooseDrawerModule.showChooseDrawer(function (
        chooseFileList
      ) {
        if (chooseFileList.length > 0) {
          switch (imageType) {
            case "pc_bg_source":
              _me.edit_model.pc_bg_source = chooseFileList[0].file_url;
              break;
            case "pc_title_source":
              _me.edit_model.pc_title_source = chooseFileList[0].file_url;
              break;
            case "pc_info_source":
              _me.edit_model.pc_info_source = chooseFileList[0].file_url;
              break;
            case "wap_bg_source":
              _me.edit_model.wap_bg_source = chooseFileList[0].file_url;
              break;
            case "wap_title_source":
              _me.edit_model.wap_title_source = chooseFileList[0].file_url;
              break;
            case "wap_info_source":
              _me.edit_model.wap_info_source = chooseFileList[0].file_url;
              break;
            default:
              break;
          }
        }
      },
      imageType);
    },
    styleInputOnclick(value) {
      switch (value) {
        case 0:
          this.edit_model.pc_bg_json = JSON.stringify(this.pc_bg_json);
          // this.edit_model.pc_bg_style = this.buildPxToRem(this.pc_bg_json)
          this.edit_model.pc_bg_style = this.buildPxToPercentage(
            this.pc_bg_json
          );
          break;
        case 1:
          this.edit_model.pc_title_json = JSON.stringify(this.pc_title_json);
          // this.edit_model.pc_title_style = this.buildPxToRem(this.pc_title_json)
          this.edit_model.pc_title_style = this.buildPxToPercentage(
            this.pc_title_json
          );
          break;
        case 2:
          this.edit_model.pc_info_json = JSON.stringify(this.pc_info_json);
          // this.edit_model.pc_info_style = this.buildPxToRem(this.pc_info_json)
          this.edit_model.pc_info_style = this.buildPxToPercentage(
            this.pc_info_json
          );
          break;
        case 3:
          this.edit_model.wap_bg_json = JSON.stringify(this.wap_bg_json);
          this.edit_model.wap_bg_style = this.buildPxToRem(this.wap_bg_json);
          break;
        case 4:
          this.edit_model.wap_title_json = JSON.stringify(this.wap_title_json);
          this.edit_model.wap_title_style = this.buildPxToRem(
            this.wap_title_json
          );
          break;
        case 5:
          this.edit_model.wap_info_style = JSON.stringify(this.wap_info_json);
          this.edit_model.wap_info_json = this.buildPxToRem(this.wap_info_json);
          break;
        default:
          break;
      }
    },
    buildPxToRem(model) {
      /*
       * 除以 75 是前端以此作为基准，如果有变化或者PC与WAP基准不同，可以灵活改变 - tyz - 2021年01月25日14:10:36
       * */
      // console.log(model)
      let buildRem = "";
      if (model.width !== undefined && model.width !== 0) {
        let rem = model.width / 75;
        buildRem = buildRem + "width:" + rem.toFixed(2) + "rem;";
      }

      if (model.height !== undefined && model.height !== 0) {
        let rem = model.height / 75;
        buildRem = buildRem + "height:" + rem.toFixed(2) + "rem;";
      }

      if (model.top !== undefined && model.top !== 0) {
        let rem = model.top / 75;
        buildRem = buildRem + "top:" + rem.toFixed(2) + "rem;";
      }

      if (model.bottom !== undefined && model.bottom !== 0) {
        let rem = model.bottom / 75;
        buildRem = buildRem + "bottom:" + rem.toFixed(2) + "rem;";
      }

      if (model.left !== undefined && model.left !== 0) {
        let rem = model.left / 75;
        buildRem = buildRem + "left:" + rem.toFixed(2) + "rem;";
      }

      if (model.right !== undefined && model.right !== 0) {
        let rem = model.right / 75;
        buildRem = buildRem + "right:" + rem.toFixed(2) + "rem;";
      }
      return buildRem;
    },
    buildPxToPercentage(model) {
      /*
       * PC端采用百分比的方式
       * 除以 1920 是前端以此作为基准，如果有变化或者PC与WAP基准不同，可以灵活改变 - tyz - 2021年01月25日14:10:36
       * */
      // console.log(model)
      let buildRem = "";
      if (model.width !== undefined && model.width !== 0) {
        let rem = model.width / 19.2; // => (model.width / 1920) * 100
        buildRem = buildRem + "width:" + rem.toFixed(2) + "%;";
      }

      //   修改宽度比例/10.8 2023.1.9
      if (model.height !== undefined && model.height !== 0) {
        let rem = model.height / 10.8;
        buildRem = buildRem + "height:" + rem.toFixed(2) + "%;";
      }

      if (model.top !== undefined && model.top !== 0) {
        let rem = model.top / 10.8;
        buildRem = buildRem + "top:" + rem.toFixed(2) + "%;";
      }

      if (model.bottom !== undefined && model.bottom !== 0) {
        let rem = model.bottom / 10.8;
        buildRem = buildRem + "bottom:" + rem.toFixed(2) + "%;";
      }

      if (model.left !== undefined && model.left !== 0) {
        let rem = model.left / 19.2;
        buildRem = buildRem + "left:" + rem.toFixed(2) + "%;";
      }

      if (model.right !== undefined && model.right !== 0) {
        let rem = model.right / 19.2;
        buildRem = buildRem + "right:" + rem.toFixed(2) + "%;";
      }
      return buildRem;
    },
    async saveModelOnclick() {
      if (!(await this.submitValidate())) return;
      if (this.edit_model_id !== undefined && this.edit_model_id.length > 0) {
        this.updateOneModel();
      } else {
        this.addNewModel();
      }
    },
    async submitValidate() {
      try {
        await this.$refs["category_model"].validate();
        // 验证成功
        return true;
      } catch (err) {
        this.$message({
          message: "请检查表单",
          type: "error",
        });
        return false;
      }
    },

    addNewModel() {
      let _me = this;
      let start_time = this.$common.formatTimesToTime(new Date());
      let end_time = this.$common.getNextMonth(start_time);
      if (!this.edit_model.start_at) {
        this.edit_model.start_at = start_time;
      }
      if (!this.edit_model.end_at) {
        this.edit_model.end_at = end_time;
      }

      let begin = new Date(_me.edit_model.start_at);
      let end = new Date(_me.edit_model.end_at);
      if (begin && end) {
        if (begin.getTime() > end.getTime()) {
          _me.$message({
            message: "开始时间不能大于结束时间",
            type: "error",
          });
          return;
        }
      }

      let params = {
        class_id: _me.edit_model.class_id,
        title: _me.edit_model.title,
        subtitle: _me.edit_model.subtitle,
        msg: _me.edit_model.msg,
        last_type: _me.edit_model.last_type,
        last_page: _me.edit_model.last_page,
        number: _me.edit_model.number,
        banner_type: _me.edit_model.banner_type,
        banner_time: _me.edit_model.banner_time,
        pc_bg_source: _me.edit_model.pc_bg_source,
        pc_bg_style: _me.edit_model.pc_bg_style,
        pc_bg_json: _me.edit_model.pc_bg_json,
        pc_title_source: _me.edit_model.pc_title_source,
        pc_title_style: _me.edit_model.pc_title_style,
        pc_title_json: _me.edit_model.pc_title_json,
        pc_info_source: _me.edit_model.pc_info_source,
        pc_info_style: _me.edit_model.pc_info_style,
        pc_info_json: _me.edit_model.pc_info_json,
        wap_bg_source: _me.edit_model.wap_bg_source,
        wap_bg_style: _me.edit_model.wap_bg_style,
        wap_bg_json: _me.edit_model.wap_bg_json,
        wap_title_source: _me.edit_model.wap_title_source,
        wap_title_style: _me.edit_model.wap_title_style,
        wap_title_json: _me.edit_model.wap_title_json,
        wap_info_source: _me.edit_model.wap_info_source,
        wap_info_style: _me.edit_model.wap_info_style,
        wap_info_json: _me.edit_model.wap_info_json,
        pc_video: _me.edit_model.pc_video,
        wap_video: _me.edit_model.wap_video,
        start_at: _me.$common.formatTime(_me.edit_model.start_at, 0),
        end_at: _me.$common.formatTime(_me.edit_model.end_at, 0),
        status: _me.edit_model.status,
      };
      _me.loading = true;
      _me.api
        .add_banner(params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容添加成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    updateOneModel() {
      let _me = this;
      let begin = new Date(_me.edit_model.start_at);
      let end = new Date(_me.edit_model.end_at);
      if (begin && end) {
        if (begin.getTime() > end.getTime()) {
          _me.$message({
            message: "开始时间不能大于结束时间",
            type: "error",
          });
          return;
        }
      }
      let params = {
        id: _me.edit_model.id,
        class_id: _me.edit_model.class_id,
        title: _me.edit_model.title,
        subtitle: _me.edit_model.subtitle,
        msg: _me.edit_model.msg,
        last_type: _me.edit_model.last_type,
        last_page: _me.edit_model.last_page,
        number: _me.edit_model.number,
        banner_type: _me.edit_model.banner_type,
        banner_time: _me.edit_model.banner_time,
        pc_bg_source: _me.edit_model.pc_bg_source,
        pc_bg_style: _me.edit_model.pc_bg_style,
        pc_bg_json: _me.edit_model.pc_bg_json,
        pc_title_source: _me.edit_model.pc_title_source,
        pc_title_style: _me.edit_model.pc_title_style,
        pc_title_json: _me.edit_model.pc_title_json,
        pc_info_source: _me.edit_model.pc_info_source,
        pc_info_style: _me.edit_model.pc_info_style,
        pc_info_json: _me.edit_model.pc_info_json,
        wap_bg_source: _me.edit_model.wap_bg_source,
        wap_bg_style: _me.edit_model.wap_bg_style,
        wap_bg_json: _me.edit_model.wap_bg_json,
        wap_title_source: _me.edit_model.wap_title_source,
        wap_title_style: _me.edit_model.wap_title_style,
        wap_title_json: _me.edit_model.wap_title_json,
        wap_info_source: _me.edit_model.wap_info_source,
        wap_info_style: _me.edit_model.wap_info_style,
        wap_info_json: _me.edit_model.wap_info_json,
        pc_video: _me.edit_model.pc_video,
        wap_video: _me.edit_model.wap_video,
        start_at: _me.$common.formatTime(_me.edit_model.start_at, 0),
        end_at: _me.$common.formatTime(_me.edit_model.end_at, 0),
        status: _me.edit_model.status,
      };
      _me.loading = true;
      _me.api
        .update_banner(params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容保存成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    goBack() {
      // 返回上一级路由
      this.$router.go(-1);
    },
    // 复制信息
    copyInfo(str) {
      // 触发赋值事件，将当前的数据传值
      this.$refs.copyPaste.CopyInfo(str);
    },
    // 粘贴信息
    pasteInfo(model) {
      // 接受粘贴事件的传值数据
      this.edit_model = model;
      this.edit_model.id = this.edit_model_id;
      if (model.pc_bg_json) {
        this.pc_bg_json = JSON.parse(model.pc_bg_json);
      }
      if (model.pc_title_json) {
        this.pc_title_json = JSON.parse(model.pc_title_json);
      }
      if (model.pc_info_json) {
        this.pc_info_json = JSON.parse(model.pc_info_json);
      }
      if (model.wap_bg_json) {
        this.wap_bg_json = JSON.parse(model.wap_bg_json);
      }
      if (model.wap_title_json) {
        this.wap_title_json = JSON.parse(model.wap_title_json);
      }
      if (model.wap_info_json) {
        this.wap_info_json = JSON.parse(model.wap_info_json);
      }

      this.class_list.forEach((item) => {
        if (item.id == model.class_id) {
          this.edit_model.class_id = item.id;
        }
      });
    },
  },
};
</script>

<style scoped>
.edit-row-dt {
  padding: 5px 0 0px 0;
  margin: 0;
  width: 100%;
}
</style>
