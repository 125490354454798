<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="12">
              <el-page-header class="back" title="返回" @back="goBack" content="展示位置详情"></el-page-header>
            </el-col>
            <el-col style="text-align: right" :span="12">
              <el-button type="primary" icon="el-icon-check" @click="saveModelOnclick">保存</el-button>
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-form
          class="type-form"
          :model="edit_model"
          :rules="rules"
          ref="category_model"
          label-width="100px"
        >
          <el-form-item label="名称:" prop="name">
            <el-input v-model="edit_model.name" clearable></el-input>
          </el-form-item>
          <el-form-item label="状态:">
            <el-select v-model="edit_model.status" placeholder="请选择" loading-text="加载中...">
              <el-option
                v-for="item in $common.StatusSelectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="别名:" prop="alias">
            <el-input v-model="edit_model.alias" clearable></el-input>
          </el-form-item>
          <el-form-item label="说明:">
            <el-input
              v-model="edit_model.info"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8 }"
            ></el-input>
          </el-form-item>
          <CopyPaste
            :button_status="this.edit_model_id !== undefined && this.edit_model_id.length > 0"
            @copyInfo="copyInfo(edit_model)"
            @pasteInfo="pasteInfo"
            ref="copyPaste"
          ></CopyPaste>
        </el-form>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import CopyPaste from "../../../components/CopyPaste.vue";

export default {
  name: "BannerClassDetailView",

  components: {
    CopyPaste
  },
  data() {
    return {
      loading: false,
      rules: {
        name: [
          { required: true, message: "请输入类型名称", trigger: "blur" },
          { min: 1, message: "长度大于 1 个字符", trigger: "blur" }
        ],
        alias: [
          { required: true, message: "请输入类型别名", trigger: "blur" },
          { min: 1, message: "长度大于 1 个字符", trigger: "blur" }
        ]
      },
      edit_model_id: "",
      edit_model: {
        id: "",
        name: "",
        alias: "",
        info: "",
        status: 2
      },
      deep_copy_edit_model: {}
    };
  },
  created() {
    this.edit_model_id = this.$route.query.id;
    if (this.edit_model_id !== undefined && this.edit_model_id.length > 0) {
      // 编辑
      this.getEditModel();
    }
  },
  methods: {
    getEditModel() {
      let _me = this;
      _me.loading = true;
      let params = {
        id: _me.edit_model_id
      };
      _me.api
        .get_banner_class_id(params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.edit_model = res.data;
            this.deep_copy_edit_model = JSON.parse(
              JSON.stringify(this.edit_model)
            );
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error"
          });
        });
    },
    async saveModelOnclick() {
      if (!(await this.submitValidate())) return;
      if (this.edit_model_id !== undefined && this.edit_model_id.length > 0) {
        this.updateOneModel();
      } else {
        this.addNewModel();
      }
    },
    async submitValidate() {
      try {
        await this.$refs["category_model"].validate();
        // 验证成功
        return true;
      } catch (err) {
        this.$message({
          message: "请检查表单",
          type: "error"
        });
        return false;
      }
    },
    addNewModel() {
      let _me = this;
      let params = {
        name: _me.edit_model.name,
        alias: _me.edit_model.alias,
        info: _me.edit_model.info,
        status: _me.edit_model.status
      };
      _me.loading = true;
      _me.api
        .add_banner_class(params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容添加成功");
            _me.removeLocalStorage();
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error"
          });
        });
    },
    updateOneModel() {
      let _me = this;
      let params = {
        id: _me.edit_model.id,
        name: _me.edit_model.name,
        alias: _me.edit_model.alias,
        info: _me.edit_model.info,
        status: _me.edit_model.status
      };
      _me.loading = true;
      _me.api
        .update_banner_class(params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            let origin_model_data = this.edit_model;
            let copy_model_data = this.deep_copy_edit_model;
            for (let k1 in copy_model_data) {
              for (let k2 in origin_model_data) {
                if (
                  k1 === k2 &&
                  copy_model_data[k1] !== origin_model_data[k2]
                ) {
                  _me.removeLocalStorage();
                  break;
                }
              }
            }
            _me.$message.success("内容保存成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error"
          });
        });
    },
    removeLocalStorage() {
      this.$common.removeGlobalStorage("bannerClassList");
    },
    goBack() {
      // 返回上一级路由
      this.$router.go(-1);

      // 跳转回指定路由
      // this.$router.push('/product-detail-mount')
    },
    // 复制信息
    copyInfo(str) {
      // 触发赋值事件，将当前的数据传值
      this.$refs.copyPaste.CopyInfo(str);
    },
    // 粘贴信息
    pasteInfo(model) {
      // 接受粘贴事件的传值数据
      this.edit_model = model;
      this.edit_model.id = this.edit_model_id;
    }
  }
};
</script>

<style scoped>
.edit-row-dt {
  padding: 5px 0 0px 0;
  margin: 0;
  width: 100%;
}
</style>
