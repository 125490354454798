<template>
  <el-drawer
    size="25%"
    title="文件选择"
    :with-header="false"
    :append-to-body="true"
    :visible.sync="show_drawer"
    :direction="direction"
    :before-close="handleClose"
  >
    <div class="file-drawer-frame" v-loading="loading">
      <el-container>
        <el-header height="90px" style="background-color: #f1f1f1">
          <div style="width: 100%; margin-top: 10px">
            <el-input
              v-model="search_key"
              style="width: calc(100% - 90px)"
              placeholder="搜索当前文件夹"
              clearable
            ></el-input>
            <el-button
              style="width: 80px; margin-left: 10px"
              type="primary"
              @click="getFileList"
              >过滤</el-button
            >
          </div>
          <el-breadcrumb
            class="catalog-link"
            separator-class="el-icon-arrow-right"
          >
            <el-breadcrumb-item
              v-for="(item, index) in catalog_list"
              @click.native="breadcrumbItemOnclick(item)"
              :key="index"
            >
              {{ item.name }}
            </el-breadcrumb-item>
          </el-breadcrumb>
        </el-header>
        <el-main>
          <ul style="margin: 0; padding: 0; width: 100%; height: 100%">
            <li
              class="file-li"
              v-for="(item, i) in file_list"
              :key="'choose-drawer-' + i"
            >
              <div
                v-if="item.is_folder === true"
                style="width: 100%; height: 100%"
                @click="fileOneClick(item)"
              >
                <div style="width: 100%; height: 90px">
                  <el-image
                    class="file-icon"
                    fit="contain"
                    :src="
                      item.file_icon + $common.K_QINIU_IMAGE_SMALL_END_100_PX
                    "
                  ></el-image>
                </div>
                <div style="width: 100%; height: 30px">
                  <p
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      margin: 0 5px 0 5px;
                      font-size: 11pt;
                    "
                  >
                    {{ item.name }}
                  </p>
                </div>
              </div>
              <div
                v-if="item.is_folder === false"
                style="width: 100%; height: 100%"
              >
                <div style="width: 100%; height: 90px; position: relative">
                  <el-image
                    class="file-icon"
                    fit="contain"
                    :src="
                      getWebpSrc(
                        item.file_icon,
                        $common.K_QINIU_IMAGE_SMALL_END_100_PX
                      )
                    "
                    :title="item.name"
                    :preview-src-list="[item.file_icon]"
                  ></el-image>
                  <el-checkbox
                    style="position: absolute; top: 0; left: 0"
                    size="medium"
                    :checked="file_list[i].is_choose"
                    @change="file_list[i].is_choose = !file_list[i].is_choose"
                    :key="'checkbox' + item.id + file_list[i].is_choose"
                  ></el-checkbox>
                </div>
                <div style="width: 100%; height: 30px">
                  <p
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      margin: 0 5px 0 5px;
                      font-size: 11pt;
                    "
                  >
                    {{ item.name }}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </el-main>
        <el-footer height="60px" style="background-color: #f1f1f1">
          <div style="margin-top: 10px">
            <el-button
              style="width: calc(50% - 5px)"
              type="danger"
              @click="closeChooseFile"
              >关闭</el-button
            >
            <el-button
              style="width: calc(50% - 5px)"
              type="primary"
              @click="confirmChooseFile"
              >确认</el-button
            >
          </div>
        </el-footer>
      </el-container>
    </div>
  </el-drawer>
</template>

<script>
import utils from "../../../components/Common";

export default {
  name: "FileChooseDrawerModule",
  data() {
    return {
      loading: false,
      show_drawer: false,
      // "ltr" = 从左往右开
      // "rtl" = 从右往左开
      // "ttb" = 从上往下开
      // "btt" = 从下往上开
      direction: "rtl",
      search_key: "",
      parent_id: 0,
      page_end_id: "",
      page_size: -1,
      catalog_list: [
        {
          id: 0,
          name: "全部文件",
        },
      ],
      file_list: [],
      callbackFunction: null,
      config_rules_list: [],
      imageType: "",
    };
  },
  methods: {
    getFileList() {
      let _me = this;
      _me.loading = true;
      let params = {
        key: _me.search_key,
        parent_id: _me.parent_id,
        page_id: _me.page_end_id,
        page_flag: 2,
        page_size: _me.page_size,
        sort_fields: "is_folder,-created_at,name,-id",
      };
      _me.$common
        .httpPost("/api/file/admin/find/list", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.file_list = res.data.array === undefined ? [] : res.data.array;
            _me.catalog_list = res.data.file_path.reverse();
            for (let i = 0; i < _me.file_list.length; i++) {
              _me.file_list[i].is_choose = false;
            }
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    isImage(fileType) {
      if (fileType.length === 0) {
        return false;
      } else {
        let reg = new RegExp("^image/[a-z]*$");
        return reg.test(fileType);
      }
    },
    fileOneClick(item) {
      if (item.is_folder === true) {
        this.parent_id = item.id;
        this.getFileList();
      }
    },
    // 抽屉即将关闭
    handleClose() {
      // console.log("抽屉即将关闭")
      this.show_drawer = false;
    },
    breadcrumbItemOnclick(item) {
      this.parent_id = item.id;
      this.getFileList();
    },
    closeChooseFile() {
      this.show_drawer = false;
    },
    is_able_upload(chooseFileList, config_rules_list, type) {
      let size_ratio = 1;
      let choosed_img_size = chooseFileList[0].file_size;
      let rule_size = "";
      let current_rule_item = {};
      current_rule_item =
        config_rules_list &&
        config_rules_list.length > 0 &&
        config_rules_list.find((item) => {
          return item.variable === type;
        });
      if (
        config_rules_list &&
        config_rules_list.length > 0 &&
        current_rule_item &&
        Object.keys(current_rule_item).length > 0
      ) {
        rule_size = current_rule_item.value;
        size_ratio = choosed_img_size / rule_size;
        if (size_ratio > 1) {
          this.$message.error("图片尺寸超过配置规则大小，不能上传");
          return false;
        } else if (size_ratio > 0.6) {
          this.$message.warning("图片尺寸超过配置规则大小的60%，谨慎上传");
        }
      }
    },
    confirmChooseFile() {
      // 判断是否有组件传过来图片类型(pc还是wap)
      let imageType =
        (this.imageType &&
          Object.keys(this.imageType).length !== 0 &&
          this.imageType) ||
        "pc_wap";
      let config_rules_list = this.config_rules_list;
      let route = this.$route.path;
      let chooseFileList = [];
      let variable = "";
      let flag = true;
      for (let i = 0; i < this.file_list.length; i++) {
        if (this.file_list[i].is_choose) {
          chooseFileList.push(this.file_list[i]);
        }
      }

      // 1：配置管理规则：需要限制图片的路由+'-pc'||'-wap'(如banner-detail-wap)
      // 2：组件的getImageByWeb（imageType）需要包含pc或者wap而且以下划线_进行命名，不然不生效
      variable =
        route.slice(1) +
        "-" +
        imageType.split("_").find((item) => item === "pc" || "wap");
      flag = this.is_able_upload(chooseFileList, config_rules_list, variable);
      if (flag === false) return;
      // 回调选中的文件
      this.show_drawer = false;
      if (this.callbackFunction !== null) {
        this.callbackFunction(chooseFileList);
      }
    },
    showChooseDrawer(callback, imageType = {}) {
      this.imageType = imageType;
      if (this.file_list.length === 0) {
        this.getFileList();
      }

      for (let i = 0; i < this.file_list.length; i++) {
        this.file_list[i].is_choose = false;
      }
      if (this.show_drawer === false) {
        this.show_drawer = true;
        this.callbackFunction = callback;
      }
    },
    getWebpSrc(src, config) {
      return utils.getWebpSrc(src, config);
    },
    getConfigRuleList() {
      let _me = this;
      let params = {
        status: 0,
        page_size: 20,
        page_number: 1,
        sort_fields: "",
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/web/config/admin/find/list", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            let model_list = [];
            model_list =
              res.data.array === undefined
                ? []
                : res.data.array.filter((item) => item.status === 1);
            if (model_list && model_list.length > 0) {
              _me.config_rules_list = model_list;
            }
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
  },
  created() {
    // 获取配置规则
    this.getConfigRuleList();
  },
};
</script>

<style scoped>
.file-drawer-frame {
  width: 100%;
  height: 100%;
  min-width: 300px;
}

.el-main {
  height: 0px;
}

.catalog-link {
  width: 100%;
  margin-top: 15px;
}

.file-li {
  width: 90px;
  height: 120px;
  margin-top: 10px;
  margin-left: 10px;
  border-radius: 4px;
  float: left;
  list-style: none;
}

.file-li:hover {
  background-color: #eef2f9;
}

.file-icon {
  width: 90px;
  height: 90px;
}
</style>
